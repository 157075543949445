<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form
          action="#"
          @submit.prevent="
            showModal({ type: 'submit', data: payload.persetujuan })
          "
        >
          <div class="form-body">
            <h3 class="card-title">{{ $t("document_out") }}</h3>
            <hr />

            <div class="row p-t-20"></div>
            <div class="row p-t-20">
              <div class="col-md-4">
                <label>
                  Indeks
                  <small style="color: red">*</small>
                </label>
                <div :class="[{ invalid: isInvalidKodeSurat }]">
                  <Multiselect
                    v-model="payload.kodrat"
                    label="text"
                    track-by="value"
                    placeholder="Pilih Indeks Surat"
                    open-direction="bottom"
                    :options="dropdownKodeSurat.kodratList"
                    :multiple="false"
                    :searchable="true"
                    :loading="dropdownKodeSurat.isLoadingKodeSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @input="onChangeKodeSurat"
                    @close="onTouchKodeSurat"
                    @search-change="kodeSurat"
                  >
                    <span slot="noResult">Oops! No Data found.</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidKodeSurat"
                    >Must have at least one value</label
                  >
                </div>
              </div>
              <Input
                v-model="payload.no_urut"
                name="no_urut"
                disabled
                required
                type="hidden"
              />
              <Input
                v-model="payload.unit_id"
                name="unit_id"
                disabled
                required
                type="hidden"
              />
              <input
                disabled
                v-model="payload.agenda"
                type="hidden"
                class="form-control"
              />
              <!-- <div class="col-md-4">
                <label> -->
              <!-- {{ $t("agenda_number") }}
                  <small style="color: red">*</small> -->
              <!-- </label>
                <div>
                  <input
                    disabled
                    v-model="payload.agenda"
                    type="hidden"
                    class="form-control"
                  />
                </div> -->
              <!-- </div> -->
              <div class="col-md-4">
                <label :class="['control-label']">
                  {{ $t("document_urgency") }}
                </label>
                <!-- <div :class="[{ invalid: invalidSifatSurat }]">
                  <Multiselect
                    :disabled="editMode && can_send"
                    v-model="payload.sifat"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_document_urgency')"
                    open-direction="bottom"
                    :options="dropdownSifatSurat.urgencyList"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownSifatSurat.value"
                    :loading="dropdownSifatSurat.isLoadingSifatSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @open="sifatSurat('')"
                    @close="onTouchSifatSurat"
                    @input="onChangeSifatSurat"
                    @search-change="sifatSurat"
                  >
                    <span slot="noResult">{{ $t("data_not_found") }}</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="invalidSifatSurat"
                    >{{ $t("at_least_one") }}</label
                  >
                </div> -->
                <div>
                  <Multiselect
                    v-model="payload.sifat"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_document_urgency')"
                    open-direction="bottom"
                    :options="dropdownSifatSurat.urgencyList"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownSifatSurat.value"
                    :loading="dropdownSifatSurat.isLoadingSifatSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @open="sifatSurat('')"
                    @close="onTouchSifatSurat"
                    @input="onChangeSifatSurat"
                    @search-change="sifatSurat"
                  >
                    <span slot="noResult">{{ $t("data_not_found") }}</span>
                  </Multiselect>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  :class="[
                    'form-group',
                    { 'has-danger': errors.has('tanggal') },
                  ]"
                >
                  <label>
                    {{ $t("date") }}
                    <small style="color: red">*</small>
                  </label>
                  <div>
                    <!-- :disabled="editMode && can_send" -->
                    <Input
                      :disabled="true"
                      v-model="payload.tanggal"
                      name="tanggal"
                      placeholder="YYYY-MM-DD"
                      required
                      type="text"
                    />
                    <!-- <Datepicker
                      :disabled="(editMode && can_send)"
                      wrapper-class="fullscreen-when-on-mobile"
                      input-class="form-control normal-background"
                      v-model="payload.tanggal"
                      required
                    /> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label>
                  {{ $t("page") }}
                  <small style="color: red">*</small>
                </label>
                <div>
                  <Input
                    v-model="payload.jumlah_halaman"
                    name="jumlah_halaman"
                    required
                    type="text"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <label>
                  {{ $t("attachment") }}
                  <small style="color: red">*</small>
                </label>
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <Input
                      v-model="payload.jumlah"
                      :placeholder="$t('total')"
                      name="jumlah"
                      :min="0"
                      required
                      type="text"
                    />
                  </div>
                  <div
                    :class="['col-md-6 m-l-5', { invalid: isInvalidLampiran }]"
                  >
                    <Multiselect
                      v-model="payload.lampiran"
                      label="text"
                      track-by="value"
                      :placeholder="$t('type_to_search')"
                      open-direction="bottom"
                      :disabled="payload.jumlah === 0"
                      :options="dropdownLampiran.lampiranList"
                      :multiple="false"
                      :value="dropdownLampiran.value"
                      :searchable="true"
                      :loading="dropdownLampiran.isLoadingJenisLampiran"
                      :internal-search="false"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :options-limit="300"
                      :max-height="600"
                      :show-no-results="true"
                      :hide-selected="true"
                      :selectLabel="''"
                      @open="jenisLampiran('')"
                      @input="onChangeLampiran"
                      @close="onTouchLampiran"
                      @search-change="jenisLampiran"
                    >
                      <span slot="noResult">{{ $t("data_not_found") }}</span>
                    </Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isInvalidLampiran"
                      >{{ $t("at_least_one") }}</label
                    >
                  </div>
                </div>
              </div>

              <!-- <div class="col-md-4"> -->
              <!-- <label>
                  {{ $t("document_speed") }}
                </label> -->
              <!-- <div :class="[{ invalid: isInvalidKecepatanSurat }]">
                  <Multiselect
                    v-model="payload.kecrat"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_document_speed')"
                    open-direction="bottom"
                    :disabled="editMode && can_send"
                    :options="dropdownKecepatanSurat.kecratList"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownKecepatanSurat.value"
                    :loading="dropdownKecepatanSurat.isLoadingKecepatanSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @open="kecepatanSurat('')"
                    @close="onTouchKecepatanSurat"
                    @input="onChangeKecepatanSurat"
                    @search-change="kecepatanSurat"
                  >
                    <span slot="noResult">{{ $t("data_not_found") }}</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidKecepatanSurat"
                    >{{ $t("at_least_one") }}</label
                  >
                </div> -->
              <!-- <div>
                  <Multiselect
                    v-model="payload.kecrat"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_document_speed')"
                    open-direction="bottom"
                    :options="dropdownKecepatanSurat.kecratList"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownKecepatanSurat.value"
                    :loading="dropdownKecepatanSurat.isLoadingKecepatanSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @open="kecepatanSurat('')"
                    @close="onTouchKecepatanSurat"
                    @input="onChangeKecepatanSurat"
                    @search-change="kecepatanSurat"
                  >
                    <span slot="noResult">{{ $t("data_not_found") }}</span>
                  </Multiselect>
                </div>
              </div> -->
            </div>

            <div class="row">
              <div class="col-md-4">
                <label :class="['control-label']"> Jenis Surat </label>
                <!-- <div :class="[{ invalid: isInvalidJenisSurat }]">
                  <Multiselect
                    :disabled="editMode && can_send"
                    v-model="payload.jenis"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_document_type')"
                    open-direction="bottom"
                    :options="dropdownJenisSurat.categoryList"
                    :multiple="false"
                    :value="dropdownJenisSurat.value"
                    :searchable="true"
                    :loading="dropdownJenisSurat.isLoadingJenisSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @open="jenisSurat('')"
                    @input="onChangeJenisSurat"
                    @close="onTouchJenisSurat"
                    @search-change="jenisSurat"
                  >
                    <span slot="noResult">{{ $t("data_not_found") }}</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidJenisSurat"
                    >{{ $t("at_least_one") }}</label
                  >
                </div> -->
                <div>
                  <Multiselect
                    v-model="payload.jenis"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_document_type')"
                    open-direction="bottom"
                    :options="dropdownJenisSurat.categoryList"
                    :multiple="false"
                    :value="dropdownJenisSurat.value"
                    :searchable="true"
                    :loading="dropdownJenisSurat.isLoadingJenisSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @open="jenisSurat('')"
                    @input="onChangeJenisSurat"
                    @close="onTouchJenisSurat"
                    @search-change="jenisSurat"
                  >
                    <span slot="noResult">{{ $t("data_not_found") }}</span>
                  </Multiselect>
                </div>
              </div>

              <div class="col-md-4">
                <label :class="['control-label']"> Nama Surat </label>
                <!-- <div :class="[{ invalid: isInvalidNamaDokumen }]">
                  <Multiselect
                    v-model="payload.nama"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_document_name')"
                    open-direction="bottom"
                    :options="filteredDocumentName"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownNamaDokumen.value"
                    :loading="dropdownNamaDokumen.isLoadingNamaDokumen"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @open="namaDokumen('')"
                    @close="onTouchNamaDokumen"
                    @input="onChangeNamaDokumen"
                    @search-change="namaDokumen"
                    :disabled="
                      payload.jenis.length === 0 || (editMode && can_send)
                    "
                  >
                    <span slot="noResult">{{ $t("data_not_found") }}</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidNamaDokumen"
                    >{{ $t("at_least_one") }}</label
                  >
                </div> -->
                <div>
                  <Multiselect
                    v-model="payload.nama"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_document_name')"
                    open-direction="bottom"
                    :options="filteredDocumentName"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownNamaDokumen.value"
                    :loading="dropdownNamaDokumen.isLoadingNamaDokumen"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @open="namaDokumen('')"
                    @close="onTouchNamaDokumen"
                    @input="onChangeNamaDokumen"
                    @search-change="namaDokumen"
                  >
                    <!-- :disabled="
                      payload.jenis.length === 0 || (editMode && can_send)
                    " -->
                    <span slot="noResult">{{ $t("data_not_found") }}</span>
                  </Multiselect>
                </div>
              </div>

              <div class="col-md-4">
                <label :class="['control-label']"> Klasifikasi Akses </label>
                <!-- <div :class="[{ invalid: isInvalidKlasifikasi }]">
                  <Multiselect
                    v-model="payload.klasifikasi"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_classification')"
                    open-direction="bottom"
                    :disabled="
                      payload.nama.length === 0 || (editMode && can_send)
                    "
                    :options="dropdownKlasifikasi.klasifikasiList"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownKlasifikasi.value"
                    :loading="dropdownKlasifikasi.isLoadingKlasifikasi"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @open="klasifikasi('')"
                    @close="onTouchKlasifikasi"
                    @input="onChangeKlasifikasi"
                    @search-change="klasifikasi"
                  >
                    <span slot="noResult">{{ $t("data_not_found") }}</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidKlasifikasi"
                    >{{ $t("at_least_one") }}</label
                  >
                </div> -->
                <!-- :disabled="
                      payload.nama.length === 0 || (editMode && can_send)
                    " -->
                <div>
                  <Multiselect
                    v-model="payload.klasifikasi"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_classification')"
                    open-direction="bottom"
                    :options="dropdownKlasifikasi.klasifikasiList"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownKlasifikasi.value"
                    :loading="dropdownKlasifikasi.isLoadingKlasifikasi"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @open="klasifikasi('')"
                    @close="onTouchKlasifikasi"
                    @input="onChangeKlasifikasi"
                    @search-change="klasifikasi"
                  >
                    <span slot="noResult">{{ $t("data_not_found") }}</span>
                  </Multiselect>
                </div>
              </div>
            </div>

            <hr class="m-t-40" />
            <div class="row">
              <div class="col-md-12">
                <label>
                  {{ $t("subject") }}
                </label>
                <div>
                  <Input v-model="payload.perihal" name="perihal" type="text" />
                </div>
              </div>
            </div>

            <hr class="m-t-40" />
            <div class="row">
              <div class="col-md-12">
                <label>
                  {{ $t("receiver_unit") }}
                </label>
              </div>
              <div class="col-md-4">
                <label>
                  {{ $t("receiver_unit_type") }}
                </label>
                <!-- <div :class="[{ invalid: isInvalidTipeUnitKerja }]">
                  <Multiselect
                    v-model="payload.tuk"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_receiver_unit_type')"
                    open-direction="bottom"
                    :options="dropdownTipeUnitKerja.tukList"
                    :multiple="false"
                    :value="dropdownTipeUnitKerja.value"
                    :searchable="true"
                    :loading="dropdownTipeUnitKerja.isLoadingTipeUnitKerja"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @open="tipeUnitKerja('')"
                    @input="onChangeTipeUnitKerja"
                    @close="onTouchTipeUnitKerja"
                    @search-change="tipeUnitKerja"
                    @change="unitKerja"
                  >
                    <span slot="noResult">{{ $t("data_not_found") }}</span>
                  </Multiselect>
                  <label
                    v-if="editMode && can_send"
                    class="typo__label form__label"
                    v-show="isInvalidTipeUnitKerja"
                    >{{ $t("at_least_one") }}</label
                  >
                </div> -->
                <div>
                  <Multiselect
                    v-model="payload.tuk"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_receiver_unit_type')"
                    open-direction="bottom"
                    :options="dropdownTipeUnitKerja.tukList"
                    :multiple="false"
                    :value="dropdownTipeUnitKerja.value"
                    :searchable="true"
                    :loading="dropdownTipeUnitKerja.isLoadingTipeUnitKerja"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @open="tipeUnitKerja('')"
                    @input="onChangeTipeUnitKerja"
                    @close="onTouchTipeUnitKerja"
                    @search-change="tipeUnitKerja"
                    @change="unitKerja"
                  >
                    <span slot="noResult">{{ $t("data_not_found") }}</span>
                  </Multiselect>
                </div>
              </div>

              <div class="col-md-8">
                <label>
                  {{ $t("receiver_unit") }}
                </label>

                <div>
                  <!-- <div :class="[{ invalid: isInvalidUnitKerja }]">
                    <treeselect
                      :multiple="true"
                      :options="filteredUkerList"
                      :disabled="payload.tuk.length === 0"
                      :flat="true"
                      :sort-value-by="'INDEX'"
                      :default-expand-level="1"
                      :flatten-search-results="true"
                      :placeholder="$t('choose_receiver_unit')"
                      v-model="payload.kepada"
                      value-format="object"
                      @input="updateKepada"
                    />
                  </div> -->
                  <div>
                    <treeselect
                      :multiple="true"
                      :options="filteredUkerList"
                      :disabled="payload.tuk.length === 0"
                      :flat="true"
                      :sort-value-by="'INDEX'"
                      :default-expand-level="1"
                      :flatten-search-results="true"
                      :placeholder="$t('choose_receiver_unit')"
                      v-model="payload.kepada"
                      value-format="object"
                      @input="updateKepada"
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12">
                <label>Eksternal</label>
                <Input
                  v-model="payload.kepadaex"
                  placeholder="Silakan Isi Penerima Eksternal disini"
                  name="kepadaex"
                  type="text"
                />
              </div>
            </div>
            <hr class="m-t-40" />
            <div class="row">
              <div class="col-md-12">
                <label>
                  {{ $t("cc") }}
                </label>
              </div>
              <div class="col-md-4">
                <label>
                  {{ $t("cc_type") }}
                </label>
                <div>
                  <Multiselect
                    v-model="payload.tt"
                    label="text"
                    track-by="value"
                    :placeholder="$t('choose_cc_type')"
                    open-direction="bottom"
                    :options="dropdownTipeTembusan.tmbsList"
                    :multiple="false"
                    :value="dropdownTipeTembusan.value"
                    :searchable="true"
                    :loading="dropdownTipeTembusan.isLoadingTipeTembusan"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @open="tipeTembusan('')"
                    @input="onChangeTipeTembusan"
                    @close="onTouchTipeTembusan"
                    @search-change="tipeTembusan"
                    @change="tembusan"
                  >
                    <span slot="noResult">{{ $t("data_not_found") }}</span>
                  </Multiselect>
                </div>
              </div>

              <div class="col-md-8">
                <label>
                  {{ $t("cc") }}
                </label>
                <div>
                  <div>
                    <treeselect
                      :multiple="true"
                      :options="filteredTmbsList"
                      :flat="true"
                      :sort-value-by="'INDEX'"
                      :disabled="payload.tt.length === 0"
                      :default-expand-level="1"
                      :flatten-search-results="true"
                      :placeholder="$t('choose_cc')"
                      v-model="payload.tembusan"
                      value-format="object"
                      @input="updateTembusan"
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12">
                <label>Eksternal</label>
                <Input
                  v-model="payload.tembusanex"
                  placeholder="Silakan Isi Tembusan disini"
                  name="tembusanex"
                  type="text"
                />
              </div>
            </div>

            <hr class="m-t-40" />

            <div class="row" v-if="editMode">
              <div class="col-md-12 m-t-10 m-b-10">
                <label :class="['control-label']">Ringkasan Surat</label>
                <wysiwyg v-model="payload.ringkas" />
              </div>
            </div>

            <div class="row" v-if="editMode">
              <div class="col-md-12">
                <div class="form-group">
                  <label :class="['control-label']"
                    >{{ $t("file") }} {{ $t("attachment") }}</label
                  >
                  <h6>
                    File Lampiran dalam format PDF dengan ukuran maksimal 20MB
                  </h6>
                  <h6 v-if="!files.length">{{ $t("no_file") }}</h6>
                  <table v-else class="table table-stripped">
                    <tr>
                      <th>{{ $t("file") }}</th>
                      <th>{{ $t("action") }}</th>
                    </tr>
                    <tr v-for="file in files" v-bind:key="file.filename">
                      <td>
                        {{
                          file.filepath !== undefined
                            ? file.filename
                            : file.name
                        }}
                      </td>
                      <td>
                        <span v-if="file.filepath !== undefined">
                          <a
                            class="btn btn-success text-white"
                            @click="download(file.uuid, file.filename)"
                            ><i class="fa fa-download text-white"></i>
                          </a>
                          <a
                            @click="prev(file.uuid)"
                            class="btn btn-info text-white"
                            ><i class="fa fa-eye text-white"></i>
                          </a>
                        </span>
                        <i v-else
                          ><b>{{ $t("not_saved") }} &nbsp;&nbsp;</b></i
                        >
                        <a
                          class="btn btn-danger text-white"
                          @click="removeAttachment(file.uuid)"
                        >
                          <i class="fa fa-trash-o text-white"></i>
                        </a>
                      </td>
                    </tr>
                  </table>
                  <Gallery :uploader="uploader" />
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div class="checkbox">
                    <input v-model="payload.tandatanganAtasan" id="checkboxAtasan" type="checkbox">
                    <label for="checkboxAtasan">Perlu Tanda Tangan Atasan</label>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="form-actions">
            <button
              type="submit"
              class="btn btn-info"
              :disabled="isDisabledSubmit"
              v-if="can_send"
              @click="setApproval(2)"
              title="Simpan"
            >
              <i class="fa fa-check"></i> {{ $t("save") }}
            </button>
            &nbsp;
            <button
              v-if="editMode && can_send"
              :disabled="isDisabledSubmit"
              type="submit"
              class="btn btn-success"
              @click="setApproval(1)"
              title="Kirim"
            >
              <i class="mdi mdi-send"></i> {{ $t("send") }}
            </button>
            &nbsp;
            <button
              type="button"
              @click="showModal({ type: 'cancel' })"
              class="btn btn-inverse"
              title="Cancel"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </form>
        <ModalDefault
          v-show="isVisible"
          :title="modalTitle"
          :content="modalContent"
          :action="ActionChangeable"
          :confirmation="isConfirmation"
          :information="isInformation"
          @close="onClose"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import axios from "axios";
import store from "@/store";

export default {
  components: {
    Input,
    RotateSquare5,
    Multiselect,
  },
  computed: {
    filteredDocumentName() {
      let filter = this.payload.jenis;
      let data = this.dropdownNamaDokumen.nameList.filter((elem) => {
        return elem.parent == filter.value;
      });
      return data;
    },
    filteredUkerList() {
      let filt = this.payload.tembusan;
      if (!this.dropdownUnitKerja.ukerList.length) {
        return [];
      }
      let list = this.dropdownUnitKerja.ukerList.map((el) => {
        el.isDisabled = false;
        filt.map((er) => {
          if (el.id === er.id) {
            el.isDisabled = true;
          }
        });
        if (el.children !== undefined && el.children.length) {
          el.children = this.filterChild(filt, el.children);
        }
        return el;
      });
      let isContainEselonI = [];
      this.grabAllEselonI(list, isContainEselonI, this.payload.kepada);

      let isContainEselonII = [];
      this.grabAllEselonII(list, isContainEselonII, this.payload.kepada);

      if (isContainEselonII.length) {
        list.splice(0, 0, {
          label: "ALL ESELON II",
          id: "ALL ESELON II",
        });
      }
      if (isContainEselonI.length) {
        list.splice(0, 0, {
          label: "ALL ESELON I",
          id: "ALL ESELON I",
        });
      }
      return list;
    },
    filteredUkerListOld() {
      let filt = this.payload.tembusan;
      let list = this.dropdownUnitKerja.ukerList.filter((uker) => {
        let exist = false;
        filt.forEach((element) => {
          if (element.value == uker.value) {
            exist = true;
          }
        });
        return exist == false;
      });
      let currentId = JSON.parse(localStorage.getItem("user"));
      list = list.filter((element) => {
        return element.value != currentId.unit_id;
      });
      let isContainEselonI = list.filter((element) => {
        return (
          element.eselon != null &&
          element.eselon.includes("Eselon I") &&
          !element.eselon.includes("Eselon II") &&
          !element.eselon.includes("Eselon III") &&
          !element.eselon.includes("Eselon IV")
        );
      });
      let isContainEselonII = list.filter((element) => {
        return (
          element.eselon != null &&
          element.eselon.includes("Eselon II") &&
          !element.eselon.includes("Eselon III")
        );
      });
      if (isContainEselonII.length) {
        list.splice(0, 0, {
          text: "ALL ESELON II",
          value: "ALL ESELON II",
        });
      }
      if (isContainEselonI.length) {
        list.splice(0, 0, {
          text: "ALL ESELON I",
          value: "ALL ESELON I",
        });
      }
      return list;
    },
    filteredTmbsList() {
      let filt = this.payload.kepada;
      if (!this.dropdownTembusan.tmbsList.length) {
        return [];
      }
      let list = this.dropdownTembusan.tmbsList.map((el) => {
        el.isDisabled = false;
        filt.map((er) => {
          if (el.id === er.id) {
            el.isDisabled = true;
          }
        });
        if (el.children !== undefined && el.children.length) {
          el.children = this.filterChild(filt, el.children);
        }
        return el;
      });
      let isContainEselonI = [];
      this.grabAllEselonI(list, isContainEselonI, this.payload.tembusan);

      let isContainEselonII = [];
      this.grabAllEselonII(list, isContainEselonII, this.payload.tembusan);

      if (isContainEselonII.length) {
        list.splice(0, 0, {
          label: "ALL ESELON II",
          id: "ALL ESELON II",
        });
      }
      if (isContainEselonI.length) {
        list.splice(0, 0, {
          label: "ALL ESELON I",
          id: "ALL ESELON I",
        });
      }
      return list;
    },
    filteredTmbsListOld() {
      let filt = this.payload.kepada;
      let list = this.dropdownTembusan.tmbsList.filter((tmbs) => {
        let exist = false;
        filt.forEach((element) => {
          if (element.value == tmbs.value) {
            exist = true;
          }
        });
        return exist == false;
      });
      let currentId = JSON.parse(localStorage.getItem("user"));
      list = list.filter((element) => {
        return element.value != currentId.unit_id;
      });
      let isContainEselonI = list.filter((element) => {
        return (
          element.eselon != null &&
          element.eselon.includes("Eselon I") &&
          !element.eselon.includes("Eselon II") &&
          !element.eselon.includes("Eselon III") &&
          !element.eselon.includes("Eselon IV")
        );
      });
      let isContainEselonII = list.filter((element) => {
        return (
          element.eselon != null &&
          element.eselon.includes("Eselon II") &&
          !element.eselon.includes("Eselon III")
        );
      });
      if (isContainEselonII.length) {
        list.splice(0, 0, {
          text: "ALL ESELON II",
          value: "ALL ESELON II",
        });
      }
      if (isContainEselonI.length) {
        list.splice(0, 0, {
          text: "ALL ESELON I",
          value: "ALL ESELON I",
        });
      }
      return list;
    },
    // isInvalidKecepatanSurat() {
    //   return (
    //     this.dropdownKecepatanSurat.isTouched &&
    //     this.dropdownKecepatanSurat.value.length === 0
    //   );
    // },
    // isInvalidTipeUnitKerja() {
    //   return (
    //     this.dropdownTipeUnitKerja.isTouched &&
    //     this.dropdownTipeUnitKerja.value.length === 0
    //   );
    // },
    // isInvalidTipeTembusan() {
    //   return (
    //     this.dropdownTipeTembusan.isTouched &&
    //     this.dropdownTipeTembusan.value.length === 0
    //   );
    // },
    // isInvalidUnitKerja() {
    //   return (
    //     this.dropdownUnitKerja.isTouched &&
    //     this.dropdownUnitKerja.value.length === 0
    //   );
    // },
    isInvalidKodeSurat() {
      return (
        this.dropdownKodeSurat.isTouched &&
        this.dropdownKodeSurat.value.length === 0
      );
    },
    // isInvalidTembusan() {
    //   return (
    //     this.dropdownTembusan.isTouched &&
    //     this.dropdownTembusan.value.length === 0
    //   );
    // },
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.suratKeluar;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    // isInvalidNamaDokumen() {
    //   return (
    //     this.dropdownNamaDokumen.isTouched &&
    //     this.dropdownNamaDokumen.value.length === 0
    //   );
    // },
    // isInvalidKlasifikasi() {
    //   return (
    //     this.dropdownKlasifikasi.isTouched &&
    //     this.dropdownKlasifikasi.value.length === 0
    //   );
    // },
    // isInvalidJenisSurat() {
    //   return (
    //     this.dropdownJenisSurat.isTouched &&
    //     this.dropdownJenisSurat.value.length === 0
    //   );
    // },
    // invalidSifatSurat() {
    //   return (
    //     this.dropdownSifatSurat.isTouched &&
    //     this.dropdownSifatSurat.value.length === 0
    //   );
    // },
    isInvalidLampiran() {
      return (
        this.dropdownLampiran.isTouched &&
        this.dropdownLampiran.value.length === 0
      );
    },
  },
  data() {
    const uploader = this.fineUploader(this, "document_out");
    return {
      uploader,
      files: [],
      isDisabledSubmit: false,
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable",
      ],
      can_send: false,
      processing_unit: "",
      signing: "",
      // dropdownKecepatanSurat: {
      //   isTouched: false,
      //   kecratList: [],
      //   value: [],
      //   isLoadingKecepatanSurat: false,
      // },
      payload: {
        no_urut: "",
        unit_id: "",
        kodrat: [],
        // kecrat: "",
        tt: [],
        tuk: [],
        tas: [],
        asrat: [],
        tanggal: "",
        agenda: "",
        dari: "",
        sifat: [],
        jenis: [],
        kepada: [],
        kepadaex: "",
        tembusanex: "",
        nama: [],
        lampiran: { value: 2, text: "Lembar" },
        klasifikasi: [],
        tembusan: [],
        unitKerjaDituju: [],
        tembusanUnitTarget: [],
        perihal: "",
        referensi: "",
        valueJenisDokumen: [],
        valueSifatSurat: [],
        keterangan: "",
        ringkas: "",
        valueIndeks: [],
        nomor_dokumen: "",
        ministerApproval: "",
        tandatanganAtasan: "",
        file: "",
        jumlah: 0,
        jumlah_halaman: 1,
        persetujuan: 0,
      },
      dropdownSifatSurat: {
        isTouched: false,
        urgencyList: [],
        value: [],
        isLoadingSifatSurat: false,
      },
      dropdownJenisSurat: {
        isTouched: false,
        categoryList: [],
        value: [],
        isLoadingJenisSurat: false,
      },
      dropdownLampiran: {
        isTouched: false,
        lampiranList: [],
        value: { value: 2, text: "Lembar" },
        isLoadingJenisLampiran: false,
      },
      dropdownNamaDokumen: {
        isTouched: false,
        nameList: [],
        value: [],
        isLoadingNamaDokumen: false,
      },
      dropdownKlasifikasi: {
        isTouched: false,
        klasifikasiList: [],
        value: [],
        isLoadingKlasifikasi: false,
      },
      dropdownTujuan: {
        isTouched: false,
        userList: [],
        value: [],
        isLoadingUserFind: false,
      },
      dropdownKodeSurat: {
        isTouched: false,
        kodratList: [],
        value: [],
        isLoadingKodeSurat: false,
      },
      receiver: {
        isTouched: false,
        value: [],
        list: [],
        isLoadingUserList: false,
      },
      dropdownTipeUnitKerja: {
        isTouched: false,
        tukList: [],
        value: [],
        isLoadingTipeUnitKerja: false,
      },
      dropdownTipeTembusan: {
        isTouched: false,
        tmbsList: [],
        value: [],
        isLoadingTipeTembusan: false,
      },
      dropdownUnitKerja: {
        isTouched: false,
        ukerList: [],
        value: [],
        isLoadingUnitKerja: false,
      },
      dropdownTembusan: {
        isTouched: false,
        tmbsList: [],
        value: [],
        isLoadingTembusan: false,
      },
      myHTML: "",
      myFile: [],
      isDisabled: false,
      isModalVisible: false,
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isConfirmation: false,
      isInformation: false,
      ActionChangeable: null,
    };
  },
  async mounted() {
    this.initialize();
    this.getAllReference();
  },

  methods: {
    updateTembusan(value) {
      let isAllEselonI = value.filter((element) => {
        return (
          element.label.includes("ALL ESELON I") &&
          !element.label.includes("ALL ESELON II")
        );
      });
      let isAllEselonII = value.filter((element) => {
        return element.label.includes("ALL ESELON II");
      });
      if (isAllEselonI.length) {
        this.insertAllEselonI("tembusan");
      }
      if (isAllEselonII.length) {
        this.insertAllEselonII("tembusan");
      }
    },
    updateKepada(value) {
      let isAllEselonI = value.filter((element) => {
        return (
          element.label.includes("ALL ESELON I") &&
          !element.label.includes("ALL ESELON II")
        );
      });
      let isAllEselonII = value.filter((element) => {
        return element.label.includes("ALL ESELON II");
      });
      if (isAllEselonI.length) {
        this.insertAllEselonI("kepada");
      }
      if (isAllEselonII.length) {
        this.insertAllEselonII("kepada");
      }
    },
    insertAllEselonI(params) {
      if (params === "kepada") {
        let list = this.filteredUkerList;
        let data = [];
        this.grabAllEselonI(list, data, this.payload.kepada);
        let d = this.payload.kepada
          .concat(data)
          .filter((element) => element.id !== "ALL ESELON I");
        this.payload.kepada = d;
      } else {
        let list = this.filteredTmbsList;
        let data = [];
        this.grabAllEselonI(list, data, this.payload.tembusan);
        let d = this.payload.tembusan
          .concat(data)
          .filter((element) => element.id !== "ALL ESELON I");
        this.payload.tembusan = d;
      }
    },
    insertAllEselonII(params) {
      if (params === "kepada") {
        let list = this.filteredUkerList;
        let data = [];
        this.grabAllEselonII(list, data, this.payload.kepada);
        let d = this.payload.kepada
          .concat(data)
          .filter((element) => element.id !== "ALL ESELON II");
        this.payload.kepada = d;
      } else {
        let list = this.filteredTmbsList;
        let data = [];
        this.grabAllEselonII(list, data, this.payload.tembusan);
        let d = this.payload.tembusan
          .concat(data)
          .filter((element) => element.id !== "ALL ESELON II");
        this.payload.tembusan = d;
      }
    },
    grabAllEselonI(list, data, exist) {
      // eslint-disable-next-line no-console
      // console.log(list)
      list.map((element) => {
        if (
          element.eselon != null &&
          element.eselon.includes("Eselon I") &&
          !element.eselon.includes("Eselon II") &&
          !element.eselon.includes("Eselon III") &&
          !element.eselon.includes("Eselon IV") &&
          !element.isDisabled
        ) {
          let ex = false;
          exist.map((el) => {
            if (el.id === element.id) {
              ex = true;
            }
          });
          if (!ex) {
            data.push({ id: element.id, label: element.label });
          }
        }
        if (element.children !== undefined && element.children.length) {
          this.grabAllEselonI(element.children, data, exist);
        }
      });
    },
    grabAllEselonII(list, data, exist) {
      list.map((element) => {
        if (
          element.eselon != null &&
          element.eselon.includes("Eselon II") &&
          !element.eselon.includes("Eselon III") &&
          !element.eselon.includes("Eselon IV") &&
          !element.isDisabled
        ) {
          let ex = false;
          exist.map((el) => {
            if (el.id === element.id) {
              ex = true;
            }
          });
          if (!ex) {
            data.push({ id: element.id, label: element.label });
          }
        }
        if (element.children !== undefined && element.children.length) {
          this.grabAllEselonII(element.children, data, exist);
        }
      });
    },
    selectChildrenEselonI(list, params) {
      if (params === "kepada") {
        list.map((element) => {
          if (
            element.eselon != null &&
            element.eselon.includes("Eselon I") &&
            !element.eselon.includes("Eselon II") &&
            !element.eselon.includes("Eselon III") &&
            !element.eselon.includes("Eselon IV") &&
            !element.isDisabled
          ) {
            this.payload.kepada.push({ id: element.id, label: element.label });
          }
          if (element.children !== undefined && element.children.length) {
            this.selectChildrenEselonI(element.children, "kepada");
          }
        });
      }
    },
    isContainEselon(list, params) {
      let isContainEselonn = null;
      if (params === "eselon 1") {
        isContainEselonn = list.filter((element) => {
          let isContain =
            element.eselon != null &&
            element.eselon.includes("Eselon I") &&
            !element.eselon.includes("Eselon II") &&
            !element.eselon.includes("Eselon III") &&
            !element.eselon.includes("Eselon IV") &&
            element.isDisabled != true;
          if (element.children !== undefined && element.children.length) {
            isContain =
              isContain || this.isContainEselon(element.children, "eselon 1");
          }
          return isContain;
        });
      } else {
        isContainEselonn = list.filter((element) => {
          let isContain =
            element.eselon != null &&
            element.eselon.includes("Eselon II") &&
            !element.eselon.includes("Eselon III") &&
            !element.eselon.includes("Eselon IV") &&
            element.isDisabled != true;
          if (element.children !== undefined && element.children.length) {
            isContain =
              isContain || this.isContainEselon(element.children, "eselon 2");
          }
          return isContain;
        });
      }
      return isContainEselonn;
    },
    filterChild(filt, ls) {
      let list = ls.map((el) => {
        el.isDisabled = false;
        filt.map((er) => {
          if (el.id === er.id) {
            el.isDisabled = true;
          }
        });
        if (el.children !== undefined && el.children.length) {
          el.children = this.filterChild(filt, el.children);
        }
        return el;
      });
      return list;
    },
    removeAttachment(uuid) {
      let vm = this;
      let files = vm.uploader.methods.getUploads();
      files.map((element) => {
        if (element.uuid == uuid) {
          let folder;
          vm.files.map((ele) => {
            if (ele.uuid == uuid) {
              folder = ele.folder;
            }
          });
          vm.uploader.methods.setDeleteFileParams(
            { folder: folder },
            element.id
          );
          vm.uploader.methods.deleteFile(element.id);
        }
      });
      vm.files = vm.files.filter((element) => {
        return element.uuid != uuid;
      });
    },
    onClose() {
      this.isVisible = false;
    },
    showModal(option) {
      let vm = this;
      this.isVisible = true;
      if (option.type == "submit") {
        vm.isDisabled = true;
        if (option.data == 2) {
          this.modalTitle = this.$t("save_data_q");
          this.modalContent = "<p>" + this.$t("save_data_confirm_q") + "</p>";
          this.isConfirmation = true;
          this.isInformation = false;
          this.ActionChangeable = this.submit;
        } else if (option.data == 1) {
          this.modalTitle = this.$t("send_data_q");
          this.modalContent = "<p>" + this.$t("send_data_confirm_q") + "</p>";
          this.isConfirmation = true;
          this.isInformation = false;
          this.ActionChangeable = this.submit;
        }
      } else if (option.type == "cancel") {
        this.modalTitle = this.$t("cancel_q");
        this.modalContent = "<p>" + this.$t("cancel_confirm_q") + "</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      } else if (option.type == "information") {
        this.modalTitle = this.$t("success");
        // this.modalContent = this.$t("document_out_created", {
        //   no_opd: option.no_opd,
        // });
        this.isConfirmation = true;
        this.ActionChangeable = function () {
          vm.$router.push({
            name: "suratKeluar",
          });
        };
        // this.isInformation = true;
        this.isInformation = false;
      }
    },
    // kecepatanSurat(query) {
    //   this.dropdownKecepatanSurat.isLoadingKecepatanSurat = true;
    //   axios
    //     .get(`document_speeds/list?s=${query}`)
    //     .then((res) => {
    //       this.dropdownKecepatanSurat.kecratList = res.data.items;
    //       this.dropdownKecepatanSurat.isLoadingKecepatanSurat = false;
    //     })
    //     .catch((err) => {
    //       if (err.response.status === 401) {
    //         this.$store.dispatch("auth/logout");
    //       }
    //     });
    // },
    // onChangeKecepatanSurat(value) {
    //   this.dropdownKecepatanSurat.value = value;
    // },
    // onTouchKecepatanSurat() {
    //   this.dropdownKecepatanSurat.isTouched = true;
    // },
    setApproval(value) {
      this.payload.persetujuan = value;
    },
    getAllReference() {
      var query = "";
      this.kodeSurat(query);
      // this.kecepatanSurat(query);
      // this.asyncFind(query);
      // this.sifatSurat(query);
      // this.tipeUnitKerja(query);
      // this.tipeTembusan(query);
      // this.jenisSurat(query);
      // this.namaDokumen(query);
      // this.klasifikasi(query);
      // this.jenisLampiran(query);
      axios.get(`/document_out/new`).then((response) => {
        if (!this.editMode) {
          this.payload.agenda = response.data.data.agenda_number;
          this.payload.no_urut = response.data.data.no_urut;
          this.payload.unit_id = response.data.data.unit_id;
          this.payload.tanggal = response.data.data.document_date;
        }
        this.signing = response.data.data.signing;
        this.processing_unit = response.data.data.processing_unit;
      });
    },
    async initialize() {
      let _ = this;
      try {
        if (this.editMode) {
          // eslint-disable-next-line no-undef
          $.ajax({
            url: `document_out_already/${_.$route.params.id}`,
            type: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }).done(function (response) {
            let address = `unit-group/listByLevel?type_code=${response.data.receiver_type.value}`;
            let address2 = `unit-group/listByLevel?type_code=${response.data.carbon_type.value}`;
            axios
              .get(address)
              .then((res) => {
                _.dropdownUnitKerja.ukerList = res.data.items;
                axios
                  .get(address2)
                  .then((ress) => {
                    _.dropdownTembusan.tmbsList = ress.data.items;
                    const state = {
                      loaded: true,
                    };
                    _.$store.commit("suratKeluar/STATE", state);
                    _.edit(response.data);
                  })
                  .catch((err) => {
                    if (err.response.status === 401) {
                      _.$store.dispatch("auth/logout");
                    }
                  });
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  _.$store.dispatch("auth/logout");
                }
              });
          });

          // axios.get(`/document_out/${this.$route.params.id}`).then(response => {
          //   const state = {
          //     loaded: true
          //   };
          //   console.log(response)
          //   this.$store.commit("suratKeluar/STATE", state);
          //   this.edit(response.data.data);
          // });
        } else {
          axios
            .get(`attachment_types/list`)
            .then((res) => {
              for (let at in res.data.items) {
                if (res.data.items[at].text == "Lembar") {
                  this.dropdownLampiran.value = res.data.items[at];
                  this.payload.lampiran = res.data.items[at];
                }
              }
            })
            .catch(() => {});
          this.$store.dispatch("suratKeluar/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        klasifikasi: data.clasification_type,
        nomor_dokumen: data.document_number,
        kodrat: data.document_kodrat,
        tanggal: data.document_date,
        agenda: data.agenda_number,
        no_urut: data.no_urut,
        unit_id: data.unit_id,
        jenis: data.document_category,
        perihal: data.subject,
        kepadaex: data.receiverex,
        tembusanex: data.carbonex,
        jumlah: data.attachment_total,
        lampiran: data.attachment_type,
        sifat: data.document_urgency,
        // kecrat: data.document_speed,
        nama: data.document_name,
        ringkas: data.description,
        myHTML: data.description,
        kepada: data.receiver,
        tembusan: data.carbon,
        tuk: data.receiver_type,
        tt: data.carbon_type,
        unitKerjaDituju: ["hallo", ""],
        tembusanUnitTarget: [],
        files: data.files,
        jumlah_halaman: data.pages,
        ministerApproval: data.minister_approval,
        tandatanganAtasan: data.superior,
      };
      this.can_send = data.can_send;
      this.files = data.files;
      this.dropdownKecepatanSurat.value = data.document_speed;
      this.dropdownSifatSurat.value = data.document_urgency;
      this.dropdownJenisSurat.value = data.document_category;
      this.dropdownLampiran.value = data.attachment_type;
      this.dropdownKlasifikasi.value = data.clasification_type;
      this.dropdownNamaDokumen.value = data.document_name;
      // this.dropdownUnitKerja.value = data.receiver;
      this.dropdownTipeUnitKerja.value = data.receiver_type;
      // this.dropdownTembusan.value = data.carbon;
      this.dropdownKodeSurat.value = data.document_kodrat;
      this.dropdownTipeTembusan.value = data.carbon_type;
      this.myHTML = data.description;
      this.unitKerja("");
      this.tembusan("");
    },
    goBack() {
      this.$store.dispatch("suratKeluar/onCancel");
    },
    onTouchTipeUnitKerja() {
      this.dropdownTipeUnitKerja.isTouched = true;
    },
    onTouchTipeTembusan() {
      this.dropdownTipeTembusan.isTouched = true;
    },
    onTouchUnitKerja() {
      this.dropdownUnitKerja.isTouched = true;
    },
    onTouchTembusan() {
      this.dropdownTembusan.isTouched = true;
    },
    onChangeTipeUnitKerja(value) {
      this.dropdownTipeUnitKerja.value = value;
      this.unitKerja();
    },
    onChangeTipeTembusan(value) {
      this.dropdownTipeTembusan.value = value;
      this.tembusan();
    },
    onChangeUnitKerja(value) {
      let isAllEselonI = value.filter((element) => {
        return (
          element.text.includes("ALL ESELON I") &&
          !element.text.includes("ALL ESELON II")
        );
      });
      let isAllEselonII = value.filter((element) => {
        return element.text.includes("ALL ESELON II");
      });
      if (isAllEselonI.length) {
        let InsertAllEselonI = this.filteredUkerList.filter((element) => {
          return (
            element.eselon != null &&
            element.eselon.includes("Eselon I") &&
            !element.eselon.includes("Eselon II") &&
            !element.eselon.includes("Eselon III") &&
            !element.eselon.includes("Eselon IV")
          );
        });
        let excludeLabel = value.filter((element) => {
          return !element.text.includes("ALL ESELON I");
        });
        this.dropdownUnitKerja.value = Array.from(
          new Set(excludeLabel.concat(InsertAllEselonI))
        );
        this.payload.kepada = Array.from(
          new Set(excludeLabel.concat(InsertAllEselonI))
        );
      } else if (isAllEselonII.length) {
        let InsertAllEselonII = this.filteredUkerList.filter((element) => {
          return (
            element.eselon != null &&
            element.eselon.includes("Eselon II") &&
            !element.eselon.includes("Eselon III")
          );
        });
        let excludeLabel = value.filter((element) => {
          return !element.text.includes("ALL ESELON II");
        });
        this.dropdownUnitKerja.value = Array.from(
          new Set(excludeLabel.concat(InsertAllEselonII))
        );
        this.payload.kepada = Array.from(
          new Set(excludeLabel.concat(InsertAllEselonII))
        );
      } else {
        this.dropdownUnitKerja.value = value;
      }
    },
    onChangeTembusan(value) {
      let isAllEselonI = value.filter((element) => {
        return (
          element.text.includes("ALL ESELON I") &&
          !element.text.includes("ALL ESELON II")
        );
      });
      let isAllEselonII = value.filter((element) => {
        return element.text.includes("ALL ESELON II");
      });
      if (isAllEselonI.length) {
        let InsertAllEselonI = this.filteredTmbsList.filter((element) => {
          return (
            element.eselon != null &&
            element.eselon.includes("Eselon I") &&
            !element.eselon.includes("Eselon II") &&
            !element.eselon.includes("Eselon III") &&
            !element.eselon.includes("Eselon IV")
          );
        });
        let excludeLabel = value.filter((element) => {
          return !element.text.includes("ALL ESELON I");
        });
        this.dropdownTembusan.value = Array.from(
          new Set(excludeLabel.concat(InsertAllEselonI))
        );
        this.payload.tembusan = Array.from(
          new Set(excludeLabel.concat(InsertAllEselonI))
        );
      } else if (isAllEselonII.length) {
        let InsertAllEselonII = this.filteredTmbsList.filter((element) => {
          return (
            element.eselon != null &&
            element.eselon.includes("Eselon II") &&
            !element.eselon.includes("Eselon III")
          );
        });
        let excludeLabel = value.filter((element) => {
          return !element.text.includes("ALL ESELON II");
        });
        this.dropdownTembusan.value = Array.from(
          new Set(excludeLabel.concat(InsertAllEselonII))
        );
        this.payload.tembusan = Array.from(
          new Set(excludeLabel.concat(InsertAllEselonII))
        );
      } else {
        this.dropdownTembusan.value = value;
      }
    },
    tipeUnitKerja(query) {
      this.dropdownTipeUnitKerja.isLoadingTipeUnitKerja = true;
      axios
        .get(`typeSurat/list?s=${query}`)
        .then((res) => {
          this.dropdownTipeUnitKerja.tukList = res.data.items;
          this.dropdownTipeUnitKerja.isLoadingTipeUnitKerja = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    tipeTembusan(query) {
      this.dropdownTipeTembusan.isLoadingTipeTembusan = true;
      axios
        .get(`typeSurat/list?s=${query}`)
        .then((res) => {
          this.dropdownTipeTembusan.tmbsList = res.data.items;
          this.dropdownTipeTembusan.isLoadingTipeTembusan = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    unitKerja(query) {
      const mv = this;
      mv.dropdownUnitKerja.isLoadingUnitKerja = true;
      let address = "";
      // if(mv.payload.tuk.value == 3){
      //   address = `kategoriAsalSurat/listByLevel?s=${query ? query : ""}`
      // }else{
      address = `unit-group/listByLevel?s=${query ? query : ""}&type_code=${
        mv.payload.tuk.value
      }`;
      // }
      axios
        .get(address)
        .then((res) => {
          mv.dropdownUnitKerja.ukerList = res.data.items;
          mv.dropdownUnitKerja.isLoadingUnitKerja = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            mv.$store.dispatch("auth/logout");
          }
        });
    },
    tembusan(query) {
      const mv = this;
      mv.dropdownTembusan.isLoadingTembusan = true;
      let address = "";
      // if(mv.payload.tt.value == 3){
      //   address = `kategoriAsalSurat/listByLevel?s=${query ? query : ""}`
      // }else{
      address = `unit-group/listByLevel?s=${query ? query : ""}&type_code=${
        mv.payload.tt.value
      }`;
      // }
      axios
        .get(address)
        .then((res) => {
          mv.dropdownTembusan.tmbsList = res.data.items;
          mv.dropdownTembusan.isLoadingTembusan = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            mv.$store.dispatch("auth/logout");
          }
        });
    },
    submit() {
      // this.onTouchTipeUnitKerja();
      // this.onTouchUnitKerja();
      // if (!this.validateDate(this.payload.tanggal)) {
      //   const message = {
      //     title: this.$t("error"),
      //     text: "Format tanggal harus sesuai format YYYY-MM-DD dan berupa tanggal yang valid",
      //     type: "error",
      //   };
      //   this.$store.commit("addNotification", message);
      // }
      this.onTouchNamaDokumen();
      this.onTouchSifatSurat();
      // this.onTouchKecepatanSurat();
      this.onTouchKlasifikasi();
      this.onTouchJenisSurat();
      if (
        this.dropdownSifatSurat.value.length === 0 &&
        this.dropdownKodeSurat.value.length === 0 &&
        this.dropdownJenisSurat.value.length === 0 &&
        this.dropdownLampiran.value.length === 0 &&
        // this.dropdownUnitKerja.value.length === 0 &&
        // this.dropdownTipeUnitKerja.value.length === 0 &&
        this.dropdownNamaDokumen.value.length === 0 &&
        this.dropdownKecepatanSurat.value.length === 0 &&
        this.dropdownKlasifikasi.value.length === 0
      ) {
        this.dropdownSifatSurat.isTouched = true;
        this.dropdownJenisSurat.isTouched = true;
        this.dropdownLampiran.isTouched = true;
        this.dropdownKodeSurat.isTouched = true;
        // this.dropdownTipeUnitKerja.isTouched = true;
        // this.dropdownUnitKerja.isTouched = true;
        this.dropdownNamaDokumen.isTouched = true;
        this.dropdownKecepatanSurat.isTouched = true;
        this.dropdownKlasifikasi.isTouched = true;
        window.scrollTo(0, 0);
      }
      if (this.payload.file) {
        this.myFile = this.payload.file.split(",");
      }
      const payload = {
        clasification_type: this.payload.klasifikasi,
        receiver_type: this.payload.tuk,
        document_kodrat: this.payload.kodrat,
        carbon_type: this.payload.tt,
        receiver: this.payload.kepada.map((el) => {
          return {
            id: el.id,
            label: el.label,
          };
        }),
        carbon: this.payload.tembusan.map((el) => {
          return {
            id: el.id,
            label: el.label,
          };
        }),
        agenda_number: this.payload.agenda,
        no_urut: this.payload.no_urut,
        unit_id: this.payload.unit_id,
        document_date: this.payload.tanggal,
        document_urgency: this.payload.sifat,
        document_category: this.payload.jenis,
        // document_speed: this.payload.kecrat,
        subject: this.payload.perihal,
        receiverex: this.payload.kepadaex,
        carbonex: this.payload.tembusanex,
        document_name: this.payload.nama,
        document_number: this.payload.nomor_dokumen,
        // description: this.myHTML,
        description: this.payload.ringkas,
        files: this.files,
        pages: this.payload.jumlah_halaman,
        attachment_total: this.payload.jumlah,
        attachment_type: this.payload.lampiran,
        minister_approval: this.payload.ministerApproval ? 1 : 0,
        approval: this.payload.persetujuan,
        superior: this.payload.tandatanganAtasan ? 1 : 0,
      };

      console.log(this.can_send);
      const data = JSON.stringify(payload);
      this.$validator
        .validateAll()
        .then((success) => {
          if (
            success
            // !this.isInvalidJenisSurat &&
            // !this.isInvalidNamaDokumen &&
            // !this.isInvalidKlasifikasi &&
            // !this.isInvalidLampiran &&
            // !this.isInvalidUnitKerja &&
            // !this.isInvalidTipeUnitKerja &&
            // !this.isInvalidKecepatanSurat &&
            // !this.invalidSifatSurat &&
            // this.validateDate(this.payload.tanggal)
          ) {
            const state = {
              loaded: false,
            };
            this.$store.commit("suratKeluar/STATE", state);
            if (this.payload.persetujuan == 2) {
              if (this.editMode && this.can_send) {
                this.onTouchTipeUnitKerja();
                this.onTouchUnitKerja();
                // if (this.payload.kepada.length && !this.isInvalidTipeUnitKerja) {
                // if (this.payload.kepada.length || this.payload.kepadaex) {
                  this.$store
                    .dispatch("documentAlreadySend/submitEdit", {
                      data,
                      id: this.$route.params.id,
                    })
                    .then((result) => {
                      const state = {
                        loaded: true,
                      };
                      this.$store.commit("documentAlreadySend/STATE", state);
                      this.showModal({
                        type: "information",
                        document_number: result.data.data.document_number,
                      });
                      this.isVisible = true;
                    })
                    .catch(() => {
                      const state = {
                        loaded: true,
                      };
                      this.$store.commit("documentAlreadySend/STATE", state);
                      this.isVisible = false;
                    });
                // } else {
                //   const state = {
                //     loaded: true,
                //   };
                //   this.$store.commit("suratKeluar/STATE", state);
                //   const message = {
                //     title: this.$t("error"),
                //     text: this.$t("fill_form_first"),
                //     type: "error",
                //   };
                //   this.$store.commit("addNotification", message);
                //   window.scrollTo(0, 0);
                //   this.isVisible = false;
                // }
              } else if (this.editMode) {
                this.$store
                  .dispatch("documentAlreadySend/submitEdit", {
                    data,
                    id: this.$route.params.id,
                  })
                  .then((result) => {
                    const state = {
                      loaded: true,
                    };
                    this.$store.commit("documentAlreadySend/STATE", state);
                    this.showModal({
                      type: "information",
                      document_number: result.data.data.document_number,
                    });
                    this.isVisible = true;
                  })
                  .catch(() => {
                    const state = {
                      loaded: true,
                    };
                    this.$store.commit("documentAlreadySend/STATE", state);
                    this.isVisible = false;
                  });
              } else {
                this.$store.dispatch("documentAlreadySend/submitAdd", data);
                // this.$store
                //   .dispatch("suratKeluar/submitAdd", data)
                //   .then((result) => {
                //     const state = {
                //       loaded: true,
                //     };
                //     this.$store.commit("suratKeluar/STATE", state);
                //     // this.showModal({
                //     //   type: "information",
                //     //   document_number: result.data.data.document_number,
                //     // });
                //     // this.isVisible = true;
                //   })
                //   .catch(() => {
                //     const state = {
                //       loaded: true,
                //     };
                //     this.$store.commit("suratKeluar/STATE", state);
                //     this.isVisible = false;
                //   });
              }
            } else if (this.payload.persetujuan == 1) {
              if (this.editMode && this.can_send) {
                this.onTouchTipeUnitKerja();
                this.onTouchUnitKerja();
                // if (this.payload.kepada.length && !this.isInvalidTipeUnitKerja) {
                // if (this.payload.kepada.length || this.payload.kepadaex) {
                  this.$store
                    .dispatch("suratKeluar/submitEdit", {
                      data,
                      id: this.$route.params.id,
                    })
                    .then((result) => {
                      const state = {
                        loaded: true,
                      };
                      this.$store.commit("suratKeluar/STATE", state);
                      this.showModal({
                        type: "information",
                        document_number: result.data.data.document_number,
                      });
                      this.isVisible = true;
                    })
                    .catch(() => {
                      const state = {
                        loaded: true,
                      };
                      this.$store.commit("suratKeluar/STATE", state);
                      this.isVisible = false;
                    });
                // } else {
                //   const state = {
                //     loaded: true,
                //   };
                //   this.$store.commit("suratKeluar/STATE", state);
                //   const message = {
                //     title: this.$t("error"),
                //     text: this.$t("fill_form_first"),
                //     type: "error",
                //   };
                //   this.$store.commit("addNotification", message);
                //   window.scrollTo(0, 0);
                //   this.isVisible = false;
                // }
              } else if (this.editMode) {
                this.$store
                  .dispatch("suratKeluar/submitEdit", {
                    data,
                    id: this.$route.params.id,
                  })
                  .then((result) => {
                    const state = {
                      loaded: true,
                    };
                    this.$store.commit("suratKeluar/STATE", state);
                    this.showModal({
                      type: "information",
                      document_number: result.data.data.document_number,
                    });
                    this.isVisible = true;
                  })
                  .catch(() => {
                    const state = {
                      loaded: true,
                    };
                    this.$store.commit("suratKeluar/STATE", state);
                    this.isVisible = false;
                  });
              } else {
                this.$store.dispatch("suratKeluar/submitAdd", data);
                // this.$store
                //   .dispatch("suratKeluar/submitAdd", data)
                //   .then((result) => {
                //     const state = {
                //       loaded: true,
                //     };
                //     this.$store.commit("suratKeluar/STATE", state);
                //     // this.showModal({
                //     //   type: "information",
                //     //   document_number: result.data.data.document_number,
                //     // });
                //     // this.isVisible = true;
                //   })
                //   .catch(() => {
                //     const state = {
                //       loaded: true,
                //     };
                //     this.$store.commit("suratKeluar/STATE", state);
                //     this.isVisible = false;
                //   });
              }
            }
            
          } else {
            const state = {
              loaded: true,
            };
            this.$store.commit("documentAlreadySend/STATE", state);
            const message = {
              title: this.$t("error"),
              text: this.$t("fill_form_first"),
              type: "error",
            };
            this.isDisabledSubmit = false;
            this.$store.commit("addNotification", message);
            window.scrollTo(0, 0);
            this.isVisible = false;
          }
        })
        .catch(() => {
          this.isDisabledSubmit = false;
        });
    },
    clearAll() {
      this.payload.kepada = [];
    },
    asyncFind(query) {
      this.dropdownTujuan.isLoadingUserFind = true;
      axios
        .get(`units/list?s=${query}`)
        .then((res) => {
          this.dropdownTujuan.userList = res.data.items;
          this.dropdownTujuan.isLoadingUserFind = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    sifatSurat(query) {
      this.dropdownSifatSurat.isLoadingSifatSurat = true;
      axios
        .get(`document_urgencies/list?s=${query}`)
        .then((res) => {
          this.dropdownSifatSurat.urgencyList = res.data.items;
          this.dropdownSifatSurat.isLoadingSifatSurat = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    jenisSurat(query) {
      this.dropdownJenisSurat.isLoadingJenisSurat = true;
      axios
        .get(`document_categories/list?s=${query}`)
        .then((res) => {
          this.dropdownJenisSurat.categoryList = res.data.items;
          this.dropdownJenisSurat.isLoadingJenisSurat = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    kodeSurat(query) {
      this.dropdownKodeSurat.isLoadingKodeSurat = true;
      axios
        .get(`kode_klasifikasi/list?s=${query}`)
        .then((res) => {
          this.dropdownKodeSurat.kodratList = res.data.items;
          this.dropdownKodeSurat.isLoadingKodeSurat = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    namaDokumen(query) {
      this.dropdownNamaDokumen.isLoadingNamaDokumen = true;
      axios
        .get(`clasification/list?s=${query}`)
        .then((res) => {
          this.dropdownNamaDokumen.nameList = res.data.items;
          this.dropdownNamaDokumen.isLoadingNamaDokumen = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    klasifikasi(query) {
      this.dropdownKlasifikasi.isLoadingKlasifikasi = true;
      axios
        .get(`clasificationdoc/list?s=${query}`)
        .then((res) => {
          this.dropdownKlasifikasi.klasifikasiList = res.data.items;
          this.dropdownKlasifikasi.isLoadingKlasifikasi = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    jenisLampiran(query) {
      this.dropdownLampiran.isLoadingJenisLampiran = true;
      axios
        .get(`attachment_types/list?s=${query}`)
        .then((res) => {
          this.dropdownLampiran.lampiranList = res.data.items;
          this.dropdownLampiran.isLoadingJenisLampiran = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    onTouchNamaDokumen() {
      this.nomorDokumen();
      this.dropdownNamaDokumen.isTouched = true;
    },
    onTouchKlasifikasi() {
      this.nomorDokumen();
      this.dropdownKlasifikasi.isTouched = true;
    },
    onTouchLampiran() {
      this.dropdownLampiran.isTouched = true;
    },
    onTouchJenisSurat() {
      this.dropdownJenisSurat.isTouched = true;
    },
    onTouchSifatSurat() {
      this.dropdownSifatSurat.isTouched = true;
    },
    onTouchKodeSurat() {
      this.dropdownKodeSurat.isTouched = true;
    },
    onChangeSifatSurat(value) {
      this.dropdownSifatSurat.value = value;
    },
    onChangeLampiran(value) {
      this.dropdownLampiran.value = value;
    },
    onChangeJenisSurat(value) {
      this.dropdownJenisSurat.value = value;
    },
    onChangeNamaDokumen(value) {
      this.dropdownNamaDokumen.value = value;
    },
    onChangeKodeSurat(value) {
      this.dropdownKodeSurat.value = value;
    },
    onChangeKlasifikasi(value) {
      this.dropdownKlasifikasi.value = value;
    },
    // eslint-disable-next-line no-unused-vars
    nomorDokumen(value) {
      if (
        this.payload.nama.code !== undefined &&
        this.payload.klasifikasi.code != undefined
      ) {
        if (this.payload.tandatanganAtasan) {
          this.payload.nomor_dokumen = this.payload.nama.code.replace(
            "kode klas",
            this.payload.klasifikasi.code
          );
          this.payload.nomor_dokumen = this.payload.nomor_dokumen.replace(
            "/Penandatangan",
            "/" + this.signing
          );
          this.payload.nomor_dokumen = this.payload.nomor_dokumen.replace(
            "Unit Pengolah",
            this.processing_unit
          );
        } else {
          this.payload.nomor_dokumen = this.payload.nama.code.replace(
            "kode klas",
            this.payload.klasifikasi.code
          );
          this.payload.nomor_dokumen = this.payload.nomor_dokumen.replace(
            "/Penandatangan",
            ""
          );
          this.payload.nomor_dokumen = this.payload.nomor_dokumen.replace(
            "Unit Pengolah",
            this.processing_unit
          );
        }
      }
    },
    async onChangeData(value) {
      try {
        let response = await axios.get(`/units/listById`, { value });
        response = response.data;
        this.receiver.list = response.data;
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    download(filepath, filename) {
      axios
        .post("document_out/assets/single", { filepath: filepath })
        .then((res) => {
          const linkSource = res.data;
          const downloadLink = document.createElement("a");
          const fileName = filename;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          // console.log(err);
        });
    },
    prev(string) {
      let routeData = this.$router.resolve({
        name: "preview.index",
        params: {
          document_type: "document_out",
          id: this.$route.params.id,
          file: string,
        },
      });
      window.open(routeData.href, "_blank");
    },
    onSelectUnitKerja(value) {
      this.payload.kepada.push({ value: value.value, text: value.text });
    },
    onDeselectUnitKerja(value) {
      for (let ke in this.payload.kepada) {
        if (this.payload.kepada[ke].value == value.value) {
          this.payload.kepada.splice(ke, 1);
        }
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
.normal-background {
  background-color: #fff !important;
}
</style>
<style lang="scss">
.vdp-datepicker {
  &.fullscreen-when-on-mobile {
    @media (max-width: 767px) {
      position: static;
      .vdp-datepicker__calendar {
        position: fixed;
        top: 50%;
        left: 5%;
        margin-top: -25%;
        width: 90%;
      }
    }
  }
}
</style>
